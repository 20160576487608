<template>
  <div class="card" :class="$style.map">
    <div :class="$style.content">
      <div :class="$style.contentWrapper">
        <div class="card-body" style="padding: 0 !important">
          <div>
            <div v-if="mapLoading" class="text-center" style="width: 100%; height: 80vh; padding-top: 200px; margin-bottom: 5000px;">
              <a-spin tip="Loading..." />
            </div>
          </div>
          <GmapMap
            ref="gMap"
            :center="{lat:35.517007, lng:139.613112}"
            :zoom="15"
            map-type-id="roadmap"
            :options="{
              styles: mapStyle,
              streetViewControl: 'true',
              disableDefaultUi: 'true'
            }"
            :style="gMapStyle"
          >
            <GmapMarker
              :key="m.id"
              v-for="(m, index) in markersDriving"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :icon="m.icon"
              @click="selectMarkerDriving(index)"
            />
            <GmapMarker
              :key="m.id"
              v-for="(m, index) in markersStop"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :icon="m.icon"
              @click="selectMarkerStop(index)"
            />
            <GmapPolyline
              :key="index"
              v-for="(l, index) in lines"
              :path="l.path"
              :options="l.icons"
            />
          </GmapMap>
        </div>
      </div>
    </div>
    <div :class="$style.sidebar">
      <div :class="$style.sidebarHeader">
        <h2 :class="$style.cardTitle">車両の現在地一覧</h2>
        <div :class="$style.selectorContainer">
          <a-checkbox-group :default-value="checkStatus" @change="onCheckStatusChange">
              <span><a-checkbox value="0">走行中</a-checkbox></span>
              <span><a-checkbox value="1">停止中</a-checkbox></span>
          </a-checkbox-group>
          <a-button @click="updateMonitor" :class="$style.updateBtn">更新</a-button>
        </div>
      </div>
      <div v-if="monitorLoading" class="text-center" style="padding-top: 200px">
        <a-spin tip="Loading..." />
      </div>
      <div v-if="!monitorLoading" :class="$style.tabs">
        <div v-if="showDriving">
          <p class="list-title"><img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" /> 走行中の車両</p>
          <a-list item-layout="horizontal" :data-source="markersDriving">
            <a-list-item :class="{ active: item.isActive, stripe: index % 2 == 1 }" slot="renderItem" slot-scope="item, index" style="padding-top: 8px; padding-bottom: 8px;" @click="selectMarkerDriving(index)">
              <a-list-item-meta
                :description="item.userName ? item.name + '(' + item.userName + ') [' + item.speed + ' km/h]' : item.name + ' [' +  item.speed + ' km/h]'"
              >
                <p slot="title" style="margin-bottom: 0;">{{ item.dateTime | momentDateTimeAdd9Hour }}</p>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
        <div v-if="showStop">
          <p class="list-title"><img src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png" /> 停止中の車両</p>
          <a-list item-layout="horizontal" :data-source="markersStop" v-if="showStop">
            <a-list-item :class="{ active: item.isActive, stripe: index % 2 == 1 }" slot="renderItem" slot-scope="item, index" style="padding-top: 8px; padding-bottom: 8px;" @click="selectMarkerStop(index)">
              <a-list-item-meta
                :description="item.name"
              >
                <p slot="title" style="margin-bottom: 0;" v-if="item.dateTime">{{ item.dateTime }}</p>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import moment from 'moment'
import { mapStyle } from '@/services/mapStyle'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA-KH18hgZoLsK7-zOueBHrnA-4VZJmQVQ',
    libraries: 'places',
    region: 'JP',
    language: 'ja',
  },
})
Vue.config.productionTip = false

export default {
  name: 'driveMonitorMap',
  data() {
    return {
      mapLoading: true,
      monitorLoading: true,
      edit: false,
      editBtnName: '編集',
      gMapStyle: 'width: 100%; height: 80vh;',
      loading: false,
      mapStyle: mapStyle,
      activeMarkerIndex: null,
      gpsData: null,
      markersDriving: [],
      markersStop: [],
      lines: [],
      checkStatus: ['0'],
      getDriving: true,
      getStop: false,
      showDriving: true,
      showStop: false,
      gMap: null,
      bounds: null,
      firstFlag: 1,
    }
  },
  created() {
    this.updateMonitor()
  },
  filters: {
    momentDateTimeAdd9Hour: function (dateTime) {
      return moment(dateTime, 'YYYY-MM-DD hh:mm:ss').add(9, 'hours').format('YYYY-MM-DD HH:mm:ss')
    },
  },
  methods: {
    activeIndexReset() {
      if (this.activeMarkerIndex != null) {
        if (this.activeMarkerIndex in this.markersDriving) {
          this.markersDriving[this.activeMarkerIndex].isActive = false
          this.markersDriving[this.activeMarkerIndex].icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
        }
        if (this.activeMarkerIndex in this.markersStop) {
          this.markersStop[this.activeMarkerIndex].isActive = false
          this.markersStop[this.activeMarkerIndex].icon = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        }
      }
    },
    selectMarkerDriving(index) {
      this.activeIndexReset()
      this.markersDriving[index].isActive = true
      this.activeMarkerIndex = index
      this.gMap.setCenter(this.markersDriving[index].position)
      this.markersDriving[index].icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
      // this.gMap.setZoom(16)
    },
    selectMarkerStop(index) {
      this.activeIndexReset()
      this.markersStop[index].isActive = true
      this.activeMarkerIndex = index
      this.gMap.setCenter(this.markersStop[index].position)
      this.markersStop[index].icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
      // this.gMap.setZoom(16)
    },
    updateMonitor() {
      const _this = this
      if (this.getDriving || this.getStop) {
        this.monitorLoading = true
        this.showDriving = this.getDriving
        this.showStop = this.getStop
        let path = 'drive_monitor'
        if (!this.getStop) path += '/driving'
        else if (!this.getDriving) path += '/stop'

        const result = Vue.prototype.$api.send('get', path)
        this.markersDriving = []
        this.markersStop = []
        this.lines = []
        this.$gmapApiPromiseLazy().then(() => {
          result.then(response => {
            let bounds = new google.maps.LatLngBounds() // eslint-disable-line
            // console.log('res', response)
            response.forEach(function(val) {
              let markerLon = 0
              let markerLat = 0
              if (val.user) val.user = val.user.name
              if (val.status === 1) {
                const gpsData = JSON.parse(val.gps_data || null)
                if (gpsData) {
                  const lineIcons = { icons: [{ icon: { path: 'M0 0 l0 -2 l0 -2 l-2 6 m2 -6 l2 6' } }] }
                  const dateTime = gpsData.date_time
                  for (let i = 0; i < gpsData.array.length - 1; i++) {
                    let icon = null
                    if (i === gpsData.array.length - 2) icon = lineIcons
                    _this.lines.push({
                      path: [
                        { lat: gpsData.array[i].lat, lng: gpsData.array[i].lon },
                        { lat: gpsData.array[i + 1].lat, lng: gpsData.array[i + 1].lon },
                      ],
                      icons: icon,
                    })
                  }
                  if (gpsData.array.length) {
                    markerLon = gpsData.array[gpsData.array.length - 1].lon
                    markerLat = gpsData.array[gpsData.array.length - 1].lat
                    const markerSpeed = gpsData.array[gpsData.array.length - 1].speed.toFixed(2)
                    _this.markersDriving.push({ name: val.name, position: { lat: markerLat, lng: markerLon }, angle: parseInt(val.angle), isActive: false, dateTime: dateTime, userName: val.user, speed: markerSpeed, icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png' })
                  }
                }
              } else if (val.status === 0) {
                const markerSpeed = 0
                if (val.longitude && val.latitude) {
                  markerLon = val.longitude
                  markerLat = val.latitude
                }
                const dateTime = val.latest_gps_date_time
                if (dateTime) _this.markersStop.push({ name: val.name, position: { lat: markerLat, lng: markerLon }, angle: parseInt(val.angle), isActive: false, dateTime: dateTime, userName: val.user, speed: markerSpeed, icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png' })
              }
              if (markerLat && markerLon) {
                bounds.extend(new google.maps.LatLng(markerLat, markerLon)) // eslint-disable-line
                _this.bounds = bounds
              }
            })
            if (_this.firstFlag) {
              this.mapLoading = false
              this.gMapStyle = 'width: 100%; height: 80vh;'
              if (this.gMap) {
                this.gMap.fitBounds(bounds)
              }
              _this.firstFlag = 0
            }
            _this.markersDriving.sort(function(a, b) {
              if (a.dateTime > b.dateTime) return -1
              if (a.dateTime < b.dateTime) return 1
              return 0
            })
            _this.markersStop.sort(function(a, b) {
              if (a.dateTime > b.dateTime) return -1
              if (a.dateTime < b.dateTime) return 1
              return 0
            })
            this.monitorLoading = false
          })
        })
      } else {
        this.markersDriving = []
        this.markersStop = []
        this.lines = []
      }
    },
    onCheckStatusChange(checkedValues) {
      this.getDriving = checkedValues.includes('0')
      this.getStop = checkedValues.includes('1')
    },
  },
  mounted() {
    this.$refs.gMap.$mapPromise.then((map) => {
      this.gMap = map
    })
  },
}

</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
<style>
.list-title {
  font-size: 1.5em;
  margin: 10px 0 5px 10px;
  text-align: left;
}
.ant-list-item-meta {
  padding-left: 15px;
}
.ant-list-item.hide {
  background: #d9d9d9;
}
.ant-list-item.active {
  background: #e6f7ff;
}
</style>
